import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OwlOptions } from 'ngx-owl-carousel-o';


@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.css']
})

/***
 * Applications Component
 */
export class ApplicationsComponent implements OnInit {

  constructor(private modalService: NgbModal) { }

  /***
   * Client Review Owl Slider
   */
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      900: {
        items: 3
      }
    },
    nav: false
  };

  ngOnInit(): void {
  }

  /**
  * Open modal for show the video
  * @param content content of modal
  */
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }
  openTrialModal(content: any) {
    this.modalService.open(content, { centered: true });
  }

  /**
   * Services Data
   */
  servicesData = [
    {
      icon: "mdi mdi-cellphone-wireless h1 text-primary",
      title: "Παραγγελιοληψία",
      description: "χωρίς λάθη, με άμεση ενημέρωση για αύξηση της αποδοτικότητας."
    },
    {
      icon: "mdi mdi-account-group h1 text-primary",
      title: "Απεριόριστοι",
      description: "χρήστες και ρόλοι του καταστήματος (μπάρ, σερβιτόροι, κουζίνα)."
    },
    {
      icon: "mdi mdi-currency-eur-off h1 text-primary",
      title: "Οικονομικό",
      description: "χωρίς κρυφά κόστη, με φθηνό εξοπλισμό και σταθερή συνδρομή."
    },
    {
      icon: "mdi mdi-cellphone-text h1 text-primary",
      title: "Διαχείριση",
      description: "απομακρυσμένα και εύκολα ακόμη και από το κινητό σας."
    },
    {
      icon: "mdi mdi-chart-bar h1 text-primary",
      title: "Στατιστικά",
      description: "πλήρως παραμετροποιήσιμα για πλήρη εικόνα και έλεγχο της επιχείρησης σας."
    },
    {
      icon: "mdi mdi-receipt h1 text-primary",
      title: "Τιμολόγηση",
      description: "σε συνεργασία με τους καλύτερους παρόχους για την καλύτερη εξυπηρέτηση σας."
    }
  ];

  /**
   * Invoicing Data
   */
  invoicingData = [
    {
      icon: "mdi mdi-cellphone-wireless h1 text-primary",
      title: "Διασύνδεση με ΑΑΔΕ",
      description: "Ο Πάροχος Ηλεκτρονικής Τιμολόγησης αναλαμβάνει την έκδοση και την διαβίβαση των Παραστατικών στην ΑΑΔΕ. Τέλος στους φορολογικούς μηχανισμούς, ταμειακές μηχανές και έκδοση Ζ."
    },
    {
      icon: "mdi mdi-account-group h1 text-primary",
      title: "Αξιοπιστία",
      description: "Συνεργαζόμαστε με τους καλύτερους παρόχους για να έχετε την καλύτερη δυνατή υπηρεσία."
    },
    {
      icon: "mdi mdi-cellphone-text h1 text-primary",
      title: "Παραμετροποίηση",
      description: "Υποστηρίζουμε την μεγαλύτερη γκάμα συσκευών POS στην αγορά."
    },
    {
      icon: "mdi mdi-tooltip-edit h1 text-primary",
      title: "Διαχείριση",
      description: "Παρέχουμε πλήρη διαχερίση των παραστατικών σας καθόλη την διάρκεια έκδοσης τους."
    },
    {
      icon: "mdi mdi-currency-eur-off h1 text-primary",
      title: "Οικονομικό",
      description: "Εξοικονομείστε χρήματα από τις περιττές συσκευές και επωφεληθείτε από τις πιο ανταγωνιστικές τιμές της αγοράς."
    },
    {
      icon: "mdi mdi-credit-card-multiple h1 text-primary",
      title: "Απευθείας Πληρωμές",
      description: "Δώστε στους πελάτες σας την δυνατότητα να πληρώσουν απευθείας στην συσκευή του σερβιτόρου."
    }
  ];
}
