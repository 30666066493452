<section class="bg-half-170 d-table w-100 overflow-hidden"
         id="home">
  <div class="container">
    <div class="row align-items-center pt-4">
      <div class="col-lg-7 col-md-6">
        <div class="title-heading">
          <h1 class="heading mb-3 main-header">
            Η παραγγελιοληψία δεν ήταν ποτέ τόσο εύκολη.
          </h1>
          <p class="para-desc second-header">
            <ngx-typed-js [strings]="['εστιατόριο.', 'καφέ.', 'μπαρ.']"
                          [shuffle]="false"
                          [typeSpeed]="70"
                          [loop]="true">
              Διαχειριστείτε εύκολα και αποδοτικά το δικό σας <span class="element text-primary typing"></span>
            </ngx-typed-js>
          </p>
          <div class="mt-4 mb-5 pt-2">
            <a href="https://admin.orderify.gr/register"
               target="blank"
               class="btn btn-primary try-it-button">Δοκιμάστε το τώρα
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="classic-saas-image position-relative">
          <div class="bg-saas-shape position-relative">
            <img src="assets/images/orderify/dashboard-new.png"
                 class="mx-auto d-block main-img"
                 height="450"
                 alt="Dashboard">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section overflow-hidden">
  <div class="container pb-5 mb-md-5">
    <app-services [servicesData]="servicesData"></app-services>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container pb-5 mb-md-5 mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-7">
        <div class="saas-feature-shape-left position-relative">
          <img src="assets/images/orderify/holding-tablet.png"
               class="img-fluid mx-auto d-block rounded shadow main-img"
               alt="holding-tablet">
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title ms-lg-4">
          <h4 class="title mb-4">Παρέχουμε την καλύτερη λύση για την οργάνωση της επιχείρησης σας.</h4>
          <p class="text-muted">Επωφεληθείτε και εσείς από την πιο αποδοτική λύση μηχανοργάνωσης επιχειρήσεων μαζικής
            εστίασης.</p>
          <ul class="list-unstyled text-muted">
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                   class="uil uil-check-circle align-middle"></i></span>Σύγχρονες και εξειδικευμένες λύσεις</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                   class="uil uil-check-circle align-middle"></i></span>Έμπειρη ομάδα &amp; μεγάλη τεχνογνωσία
            </li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                   class="uil uil-check-circle align-middle"></i></span>Διαρκώς εξελισσόμενες υπηρεσίες</li>
          </ul>
          <div class="mt-4 pt-2">
            <a routerLink="/applications"
               class="btn btn-primary m-1 me-2">Διαβάστε περισσότερα <i class="uil uil-angle-right-b"></i></a>
            <!-- <a (click)="openWindowCustomClass(content)" href="javascript:void(0);" data-type="youtube"
              data-id="yba7hPeTSjk" class="btn btn-icon btn-pills btn-primary m-1">
              <i-feather name="video" class="icons"></i-feather>
            </a><span class="fw-bold text-uppercase small align-middle ms-1">Watch Now</span> -->
            <ng-template #content
                         let-modal>
              <div class="modal-header">
                <button type="button"
                        class="close"
                        aria-label="Close"
                        (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <iframe src="https://player.vimeo.com/video/99025203"
                        height="450"
                        width="780"
                        frameborder="0"
                        allowfullscreen
                        allow="autoplay; encrypted-media"></iframe>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title me-lg-4">
          <h1 class="title mb-3">Γιατί να μας επιλέξετε;</h1>
          <p class="para-desc text-muted">Αναρωτιέστε ακόμη για ποιό λόγο να προτιμήσετε το <span
                  class="text-primary fw-bold">Orderify</span>;</p>

          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-center pt-4">
                <h2>
                  <!-- <i-feather name="shield" class="fea icon-m-md text-primary"></i-feather> -->
                  <i class="mdi mdi-security fea icon-m-md text-primary"> </i>
                </h2>
                <div class="ms-3">
                  <h5>Ασφάλεια</h5>
                  <p class="text-muted mb-0">Σας περέχουμε την απόλυτη ασφάλεια για τα δεδομένα σας αλλά και των πελατών
                    σας.</p>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-12">
              <div class="d-flex align-items-center pt-4">
                <h2>
                  <!-- <i-feather name="cpu" class="fea icon-m-md text-primary"></i-feather> -->
                  <i class="mdi mdi-poll fea icon-m-md text-primary"> </i>
                </h2>
                <div class="ms-3">
                  <h5>Επιδόσεις</h5>
                  <p class="text-muted mb-0">Οι εφαρμογές μας δεν θα σας απογοητεύσουν ποτέ.</p>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-12">
              <div class="d-flex align-items-center pt-4">
                <h2>
                  <i class="mdi mdi-cloud-check-outline fea icon-m-md text-primary"> </i>
                </h2>
                <div class="ms-3">
                  <h5>Cloud-Based πλατφόρμα</h5>
                  <p class="text-muted mb-0">Αποκτήστε πρόσβαση στο κατάστημα μας από οπουδήποτε και αν βρίσκεστε.</p>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-12">
              <div class="d-flex align-items-center pt-4">
                <h2>
                  <i class="mdi mdi-receipt fea icon-m-md text-primary"> </i>
                </h2>
                <div class="ms-3">
                  <h5>Ηλεκτρονική Τιμολόγηση</h5>
                  <p class="text-muted mb-0">Εύκολη και γρήγορη έκδοση παραστατικών στις πιο ανταγωνιστικές τιμές.</p>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-12 pt-4">
              <a href="https://admin.orderify.gr/"
                 class="btn btn-outline-primary">Εγκατάσταση τώρα<i class="uil uil-angle-right-b"></i></a>
            </div>
            <!--end col-->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 order-1 order-lg-2">
        <div class="saas-feature-shape-right position-relative">
          <img src="assets/images/orderify/new-shift-transparent.png"
               class="img-fluid mx-auto d-block rounded"
               alt="new-shift-transparent">
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->


  <!-- <div class="container pt-4 mt-100 mt-60">
    <div class="row justify-content-center"
         id="counter">
      <div class="col-12 text-center">
        <div class="section-title">
          <p class="text-muted para-desc mx-auto mb-0">Ξεκινήστε άμεσα και εύκολα με την πιο ολοκληρωμένη και αποδοτική
            λύση παραγγελιοληψίας.</p>
          <div class="watch-video mt-4">
            <a href="https://admin.orderify.gr/"
               target="blank"
               class="btn btn-primary m-1 me-2">Ξεκινήστε <i class="mdi mdi-chevron-right"></i></a> -->
            <!-- <a (click)="openWindowCustomClass(content)" href="javascript:void(0)" data-type="youtube"
              data-id="yba7hPeTSjk" class="btn btn-icon btn-pills btn-primary m-1">
              <i-feather name="video" class="icons"></i-feather>
            </a><span class="fw-bold text-uppercase small align-middle ms-1">Demo</span> -->
            <!-- <ng-template #content
                         let-modal>
              <div class="modal-header">
                <button type="button"
                        class="close"
                        aria-label="Close"
                        (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body"> -->
                <!-- <youtube-player [videoId]="'TAq2GGWzbrM'" height="450" width="780">
                </youtube-player> -->
              <!-- </div>
            </ng-template>
          </div>
        </div>
      </div> -->
      <!--end col-->
    <!-- </div> -->
    <!--end row-->
  <!-- </div> -->
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Τιμοκατάλογος</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Το <span class="text-primary fw-bold">Orderify </span> κοστίζει
            λιγότερο από 1.5€ την μέρα.
            Ξεκινήστε από 35€ τον μήνα.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row align-items-center">
      <div class="col-12 mt-4 pt-2">
        <div class="text-center">
          <ul ngbNav
              #nav="ngbNav"
              class="nav nav-pills rounded-pill justify-content-center d-inline-block border py-1 px-2"
              id="pills-tab"
              role="tablist">
            <li ngbNavItem
                class="nav-item d-inline-block">
              <a ngbNavLink
                 class="nav-link px-3 rounded-pill">
                Μηνιαίο Πλάνο
              </a>
              <ng-template ngbNavContent>
                <app-shared-pricing [pricingData]="monthlyData"></app-shared-pricing>
              </ng-template>
            </li>
            <li ngbNavItem
                class="nav-item d-inline-block">
              <a ngbNavLink
                 class="nav-link px-3 rounded-pill">
                Εξάμηνο Πλάνο <span class="badge rounded-pill bg-success">5% Off </span>
              </a>
              <ng-template ngbNavContent>
                <app-shared-pricing [pricingData]="halfYearData"></app-shared-pricing>
              </ng-template>
            </li>
            <li ngbNavItem
                class="nav-item d-inline-block">
              <a ngbNavLink
                 class="nav-link px-3 rounded-pill">
                Ετήσιο Πλάνο <span class="badge rounded-pill bg-success">10% Off </span>
              </a>
              <ng-template ngbNavContent>
                <app-shared-pricing [pricingData]="yearlyData"></app-shared-pricing>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav"
               class="col-12"></div>
        </div>
      </div>
      <!--end col-->
    </div>

    <div class="row justify-content-center pt-4">
      <div class="text-center">
        <p class="text-muted para-desc mb-0 mx-auto pb-1">Η απόκτηση των Πακέτων προϋποθέτει την αγορά της βασικής
          συνδρομής.</p>
        <p class="text-muted"
           style="font-size: 75%;">
          Οι εμφανιζόμενες τιμές αφορούν συνολική εξόφληση.
          <br>
          Στις παραπάνω τιμές <strong>δεν</strong> συμπεριλαμβάνεται ο <strong>ΦΠΑ</strong>.
        </p>
      </div>
    </div>
  </div>
  <!--end container-->
  <!-- Price End -->

  <div class="container mt-100 mt-60">
    <div class="rounded bg-primary bg-gradient p-lg-5 p-4">
      <div class="row align-items-end">
        <div class="col-md-8">
          <div class="section-title text-md-start text-center">
            <h4 class="title mb-3 text-white title-dark">Δωρεάν δοκιμή και επιστροφή χρημάτων</h4>
            <p class="text-white-50 mb-0">Πραγματοποιήστε σήμερα την Online εγγραφή σας στο σύστημα μας και
              αποκτήστε το Orderify για το κατάστημας δωρεάν για 7 ημέρες. Ακόμη και να αγοράσετε κάποια συνδρομή μας,
              σας παρέχουμε δυνατότητα πλήρης επιστροφής χρημάτων μέσα στον πρώτο μήνα</p>
          </div>
        </div>
        <!--end col-->

        <div class="col-md-4 mt-4 mt-sm-0">
          <div class="text-md-end text-center">
            <a href="https://admin.orderify.gr/register"
               target="_blank"
               class="btn btn-light">Αποκτήστε το τώρα</a>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
  </div>
  <!-- <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6">
        <img src="assets/images/greece2.2.png"
             class="img-fluid shadow rounded"
             alt="voucher">
        <p class="text-muted mb-0 text-center"
           style="margin-top: 1em;">Η δράση υλοποιείται στο
          πλαίσιο του Εθνικού Σχεδίου Ανάκαμψης και Ανθεκτικότητας «Ελλάδα 2.0» με τη
          χρηματοδότηση της Ευρωπαϊκής Ένωσης – NextGenerationEU.</p>
      </div>
  
      <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title ms-lg-5">
          <h4 class="title mb-4">Εγκεκριμένος Προμηθευτής</h4>
          <p class="text-muted">Η <span class="text-primary fw-bold">Orderify</span> είναι Εγκεκριμένος Προμηθευτής για τα
            προγράμματα
            «Ψηφιακά Εργαλεία MME» & «Ψηφιακές Συναλλαγές»,
            προσφέροντας ολοκληρωμένες λύσεις και υπηρεσίες
            προς όλες τις ενδιαφερόμενες επιχειρήσεις.</p>
          <ul class="list-unstyled text-muted">
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                   class="uil uil-check-circle align-middle"></i></span>Ψηφιακά Εργαλεία MME</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                   class="uil uil-check-circle align-middle"></i></span>Ψηφιακές Συναλλαγές</li>
          </ul>
          <a routerLink="/contact"
             class="mt-3 h6 text-primary">Επικοινωνήστε μαζί μας για περισσότερες πληροφορίες <i
               class="uil uil-angle-right-b"></i></a>
        </div>
      </div>
    </div>
  </div> -->

  <section class="section pb-0"
           id="contact">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4"
                data-aos="fade-up"
                data-aos-duration="1000">Επικοινωνήστε μαζί μας</h4>
            <p class="text-muted para-desc mx-auto mb-0"
               data-aos="fade-up"
               data-aos-duration="1400">Στείλτε το μήνυμα σας στην ομάδα του
              <span class="text-primary fw-bold">Orderify</span> και εμείς θα σας απαντήσουμε άμεσα σε οποιοδήποτε
              αίτημα.
            </p>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->

      <div class="row align-items-center">
        <div class="col-lg-5 col-md-6 mt-4 pt-2 order-2 order-md-1">
          <div class="card rounded shadow border-0"
               data-aos="fade-up"
               data-aos-duration="1000">
            <div class="card-body">
              <div class="custom-form bg-white">
                <div id="message"></div>
                <div [formGroup]="contactForm">
                  <p id="error-msg"
                     class="mb-0"></p>
                  <div id="simple-msg"></div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label">Ονοματεπώνυμο<span class="text-danger">*</span></label>
                        <div class="form-icon position-relative">
                          <i-feather name="user"
                                     class="fea icon-sm icons"></i-feather>
                          <input id="name"
                                 type="text"
                                 class="form-control ps-5"
                                 formControlName="name"
                                 placeholder="Το ονοματεπώνυμο σας:">
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label">Email<span class="text-danger">*</span></label>
                        <div class="form-icon position-relative">
                          <i-feather name="mail"
                                     class="fea icon-sm icons"></i-feather>
                          <input id="email"
                                 type="email"
                                 class="form-control ps-5"
                                 formControlName="email"
                                 placeholder="Το Email σας:">
                        </div>
                      </div>
                    </div>
                    <!--end col-->

                    <div class="col-12">
                      <div class="mb-3">
                        <label class="form-label">Τηλέφωνο</label>
                        <div class="form-icon position-relative">
                          <i-feather name="phone"
                                     class="fea icon-sm icons"></i-feather>
                          <input id="subject"
                                 class="form-control ps-5"
                                 formControlName="phone"
                                 placeholder="Τηλέφωνο επικοινωνίας:">
                        </div>
                      </div>
                    </div>
                    <!--end col-->

                    <div class="col-12">
                      <div class="mb-3">
                        <label class="form-label">Μήνυμα<span class="text-danger">*</span></label>
                        <div class="form-icon position-relative">
                          <i-feather name="message-circle"
                                     class="fea icon-sm icons clearfix"></i-feather>
                          <textarea id="comments"
                                    rows="4"
                                    class="form-control ps-5"
                                    formControlName="text"
                                    placeholder="Το μήνυμα σας:"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="d-grid">
                        <button mat-raised-button
                                type="button"
                                *ngIf="!isSending"
                                id="submit-btn"
                                [disabled]="isSending"
                                (click)="sendEmail()"
                                class="btn btn-primary">Αποστολή</button>
                        <button mat-raised-button
                                type="button"
                                *ngIf="isSending"
                                id="submit-btn"
                                [disabled]="true"
                                (click)="sendEmail()"
                                class="btn btn-primary">
                          <mat-spinner style="margin:0 auto;"
                                       diameter="25"></mat-spinner>
                        </button>
                      </div>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </div>
                <!--end form-->
              </div>
              <!--end custom-form-->
            </div>
          </div>
        </div>
        <!--end col-->

        <div class="col-lg-7 col-md-6 mt-4 pt-2 order-1 order-md-2">
          <div class="title-heading ms-lg-4">
            <h4 class="mb-4"
                data-aos="fade-up"
                data-aos-duration="1000">Στοιχεία Επικοινωνίας</h4>
            <p class="text-muted"
               data-aos="fade-up"
               data-aos-duration="1400">Επικοινωνήστε έυκολα με την ομάδα του <span
                    class="text-primary fw-bold">Orderify </span> με έναν από τους παρακάτω τρόπους.</p>
            <div class="card border-0 bg-transparent">
              <div class="card-body p-0">
                <div class="contact-detail d-flex align-items-center mt-3"
                     data-aos="fade-up"
                     data-aos-duration="1200">
                  <div class="icon">
                    <i-feather name="mail"
                               class="fea icon-m-md text-dark me-3"></i-feather>
                  </div>
                  <div class="content overflow-hidden d-block">
                    <h6 class="fw-bold mb-0">Email</h6>
                    <a href="mailto:support@orderify.gr"
                       class="text-primary">support@orderify.gr</a>
                  </div>
                </div>
              </div>
            </div>

            <div class="card border-0 bg-transparent">
              <div class="card-body p-0">
                <div class="contact-detail d-flex align-items-center mt-3"
                     data-aos="fade-up"
                     data-aos-duration="1400">
                  <div class="icon">
                    <i-feather name="phone"
                               class="fea icon-m-md text-dark me-3"></i-feather>
                  </div>
                  <div class="content overflow-hidden d-block">
                    <h6 class="fw-bold mb-0">Τηλέφωνο Επικοινωνίας</h6>
                    <a href="tel:2102204513"
                       class="text-primary">210-220-4513</a>
                  </div>
                </div>
              </div>
            </div>

            <div class="card border-0 bg-transparent">
              <div class="card-body p-0">
                <div class="contact-detail d-flex align-items-center mt-3"
                     data-aos="fade-up"
                     data-aos-duration="1600">
                  <div class="icon">
                    <i-feather name="map-pin"
                               class="fea icon-m-md text-dark me-3"></i-feather>
                  </div>
                  <div class="content overflow-hidden d-block">
                    <h6 class="fw-bold mb-0">Διεύθυνση</h6>
                    <a (click)="mapView(mapcontent)"
                       href="javascript:void(0);"
                       data-type="iframe"
                       class="video-play-icon text-primary">Προβολή στον χάρτη</a>

                    <ng-template #mapcontent
                                 let-modal>
                      <div class="modal-header">
                        <button type="button"
                                class="close"
                                aria-label="Close"
                                (click)="modal.dismiss('Cross click')">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3148.3893988670534!2d23.76748597715363!3d37.897960005052745!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bc6a0199cb255b%3A0xe029a129474d618b!2sOrderify!5e0!3m2!1sel!2sgr!4v1713006923417!5m2!1sel!2sgr"
                                width="600"
                                height="450"
                                style="border:0;"
                                allowfullscreen=""
                                loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"></iframe>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end contact-->

  </section>
  <!--end section-->
  <!-- Section End -->


  <!-- Back to top -->
  <a href="javascript: void(0);"
     class="btn btn-icon btn-primary back-to-top"
     id="back-to-top"
     [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up"
               class="icons"></i-feather>
  </a>
  <!-- Back to top -->