<div class="row">
    <div class="col-md-3 col-12 mt-4 pt-2 text-sm-start"
         *ngFor="let data of pricingData">
        <div class="card pricing-rates business-rate shadow bg-light text-center border-0 rounded">
            <div class="ribbon ribbon-right ribbon-warning overflow-hidden"><span
                      class="text-center d-block shadow small h6">{{data.warning}}</span></div>
            <div class="card-body">
                <h6 class="title fw-bold text-uppercase text-primary mb-4">{{data.title}}</h6>
                <div class="d-flex justify-content-center mb-4">
                    <span class="price h1 mb-0">{{data.plusIcon === true ? "+": ''}}{{data.price}}</span>
                    <span class="h4 mb-0 mt-2" *ngIf="!data.hideCurrency">€</span>
                    <!-- <span class="h4 align-self-end mb-1">/mo</span> -->
                </div>
                <ul class="list-unstyled mb-0 ps-0"
                    *ngFor="let item of data.list index as i">
                    <li class="h6 text-muted mb-0"
                        style="font-weight:700"
                        [ngClass]="textLineThrough === true && i === 5 ? 'lineThrough' : ''">
                        <span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"
                               [ngClass]="textLineThrough === true && i === 5 ? 'greyIcon' : ''"></i>
                        </span>{{item}}
                    </li>
                </ul>
                <a routerLink="/contact"
                   target="blank"
                   class="btn btn-primary mt-4">{{data.btn}}</a>
            </div>
        </div>
    </div>
    <!--end col-->
</div>