import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-shared-pricing',
  templateUrl: './shared-pricing.component.html',
  styleUrls: ['./shared-pricing.component.css']
})
export class SharedPricingComponent implements OnInit {

  @Input() pricingData: Array<{
    warning?: string,
    title: string,
    price: number,
    list?: Array<[]>,
    btn?: string,
    plusIcon?: boolean,
    hideCurrency?: boolean
  }>;

  @Input()
  public textLineThrough = false;

  constructor() { }

  ngOnInit(): void {
  }

}
