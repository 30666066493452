import { Component } from '@angular/core';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})

/**
 * Pricing Component
 */
export class PricingComponent {
  /**
   * Pricing Data
   */
   pricingData = [
    {
      title: "ΜΗΝΙΑΙΟ ΠΛΑΝΟ",
      price: 49,
      list: ["Εγκατάσταση", "Δημιουργία Καταλόγου", "Εκπαίδευση Προσωπικού", "Τεχνική Υποστήριξη", "Στατιστικά"],
      btn: "Επιλέξτε το"
    },
    {
      title: "ΕΞΑΜΗΝΟ ΠΛΑΝΟ",
      price: 289,
      list: ["Εγκατάσταση", "Δημιουργία Καταλόγου", "Εκπαίδευση Προσωπικού", "Τεχνική Υποστήριξη", "Στατιστικά"],
      btn: "Επιλέξτε το"
    },
    {
      warning: "-10%",
      title: "ΕΤΗΣΙΟ ΠΛΑΝΟ",
      price: 549,
      list: ["Εγκατάσταση", "Δημιουργία Καταλόγου", "Εκπαίδευση Προσωπικού", "Τεχνική Υποστήριξη", "Στατιστικά"],
      btn: "Επιλέξτε το"
    }
  ];

  /**
   * Basic Pricing Data
   */
  monthlyData = [
    {
      title: "Βασικη Συνδρομη",
      price: 35,
      list: ["Εγκατάσταση", "Δημιουργία Καταλόγου", "Τεχνική Υποστήριξη", "Βασικά Στατιστικά", "Απεριόριστοι Χρήστες"],
      btn: "Επιλέξτε το"
    },
    {
      warning: "Add-On",
      title: "Πακετο Πελατων",
      price: 10,
      list: ["Δημιουργία QR Codes", "Κατάλογος Πελατών", "Παραγγελίες Πελατών", "Κάλεσμα Σερβιτόρου", "Ιστορικό Παραγγελιών"],
      btn: "Επιλέξτε το",
      plusIcon: true
    },
    {
      warning: "Add-On",
      title: "Πακετο Στατιστικων",
      price: 15,
      list: ["Πλήρη Στατιστικά", "Ανάλυση Πωλήσεων", "Στατιστικά Προϊόντων", "Στατιστικά Προσωπικού", "Ανάλυση Τζίρου"],
      btn: "Επιλέξτε το",
      plusIcon: true
    },
    {
      warning: "Add-On",
      title: "Πακετο Τιμολογησης",
      price: 'Custom',
      list: ["Πολύ Οικονομικό", "Σύνδεση με ΥΠΑΗΕΣ", "Πολλαπλά POS", "Διασύνδεση με ΑΑΔΕ", "Χωρίς έκδοση Ζ"],
      btn: "Επιλέξτε το",
      hideCurrency: true
    }
  ];

  /**
   * Pro Pricing Data
   */
  halfYearData = [
    {
      title: "Βασικη Συνδρομη",
      price: 199,
      list: ["Εγκατάσταση", "Δημιουργία Καταλόγου", "Τεχνική Υποστήριξη", "Βασικά Στατιστικά", "Απεριόριστοι Χρήστες"],
      btn: "Επιλέξτε το"
    },
    {
      warning: "Add-On",
      title: "Πακετο Πελατων",
      price: 50,
      list: ["Δημιουργία QR Codes", "Κατάλογος Πελατών", "Παραγγελίες Πελατών", "Κάλεσμα Σερβιτόρου", "Ιστορικό Παραγγελιών"],
      btn: "Επιλέξτε το",
      plusIcon: true
    },
    {
      warning: "Add-On",
      title: "Πακετο Στατιστικων",
      price: 75,
      list: ["Πλήρη Στατιστικά", "Ανάλυση Πωλήσεων", "Στατιστικά Προϊόντων", "Στατιστικά Προσωπικού", "Ανάλυση Τζίρου"],
      btn: "Επιλέξτε το",
      plusIcon: true
    },
    {
      warning: "Add-On",
      title: "Πακετο Τιμολογησης",
      price: 'Custom',
      list: ["Πολύ Οικονομικό", "Σύνδεση με ΥΠΑΗΕΣ", "Πολλαπλά POS", "Διασύνδεση με ΑΑΔΕ", "Χωρίς έκδοση Ζ"],
      btn: "Επιλέξτε το",
      hideCurrency: true
    }
  ];

  /**
   * Monthly Pricing Data
   */
  yearlyData = [
    {
      title: "Βασικη Συνδρομη",
      price: 389,
      list: ["Εγκατάσταση", "Δημιουργία Καταλόγου", "Τεχνική Υποστήριξη", "Βασικά Στατιστικά", "Απεριόριστοι Χρήστες"],
      btn: "Επιλέξτε το"
    },
    {
      warning: "Add-On",
      title: "Πακετο Πελατων",
      price: 96,
      list: ["Δημιουργία QR Codes", "Κατάλογος Πελατών", "Παραγγελίες Πελατών", "Κάλεσμα Σερβιτόρου", "Ιστορικό Παραγγελιών"],
      btn: "Επιλέξτε το",
      plusIcon: true
    },
    {
      warning: "Add-On",
      title: "Πακετο Στατιστικων",
      price: 150,
      list: ["Πλήρη Στατιστικά", "Ανάλυση Πωλήσεων", "Στατιστικά Προϊόντων", "Στατιστικά Προσωπικού", "Ανάλυση Τζίρου"],
      btn: "Επιλέξτε το",
      plusIcon: true
    },
    {
      warning: "Add-On",
      title: "Πακετο Τιμολογησης",
      price: 'Custom',
      list: ["Πολύ Οικονομικό", "Σύνδεση με ΥΠΑΗΕΣ", "Πολλαπλά POS", "Διασύνδεση με ΑΑΔΕ", "Χωρίς έκδοση Ζ"],
      btn: "Επιλέξτε το",
      hideCurrency: true
    }
  ];

  constructor() { }

}
