import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ContactService } from 'src/app/services/contact.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})

/***
 * Index Component
 */
export class IndexComponent implements OnInit {

  public contactForm: FormGroup = new FormGroup({
    name: new FormControl(null, Validators.required),
    email: new FormControl(null, [Validators.required, Validators.pattern('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[A-Za-z]{2,3}')]),
    phone: new FormControl(null),
    text: new FormControl(null, Validators.required)
  });

  public isSending = false;

  constructor(
    private modalService: NgbModal,
    private toastr: ToastrService,
    private contactService: ContactService
  ) { }
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      900: {
        items: 1
      }
    },
    nav: false
  };

  /**
   * Services Data
   */
  servicesData = [
    {
      icon: "uil uil-diary-alt h1 text-primary",
      title: "Φτιάξτε τον κατάλογο σας",
      description: "Με το Orderify, το κάθε κατάστημα μπορεί να διαμορφώσει τον κατάλογο που ταιριάζει στις δικές του ανάγκες."
    },
    {
      icon: "uil uil-edit h1 text-primary",
      title: "Δημιουργήστε τις βάρδιες σας",
      description: "Φτιάξτε και διαχειριστείτε εύκολα τις βάρδιες όλων των σερβιτόρων και των πόστων σας."
    },
    {
      icon: "uil uil-calendar-alt h1 text-primary",
      title: "Διαχειριστείτε την επιχείρηση",
      description: "Με το Orderify μπορείτε να έχετε συνεχή εικόνα του καταστήματος σας με την χρήση στατιστικών ειδικά εξατομικευμένα για εσάς."
    }
  ];

  /**
   * Basic Pricing Data
   */
  monthlyData = [
    {
      title: "Βασικη Συνδρομη",
      price: 35,
      list: ["Εγκατάσταση", "Δημιουργία Καταλόγου", "Τεχνική Υποστήριξη", "Βασικά Στατιστικά", "Απεριόριστοι Χρήστες"],
      btn: "Επιλέξτε το"
    },
    {
      warning: "Add-On",
      title: "Πακετο Πελατων",
      price: 10,
      list: ["Δημιουργία QR Codes", "Κατάλογος Πελατών", "Παραγγελίες Πελατών", "Κάλεσμα Σερβιτόρου", "Ιστορικό Παραγγελιών"],
      btn: "Επιλέξτε το",
      plusIcon: true
    },
    {
      warning: "Add-On",
      title: "Πακετο Στατιστικων",
      price: 15,
      list: ["Πλήρη Στατιστικά", "Ανάλυση Πωλήσεων", "Στατιστικά Προϊόντων", "Στατιστικά Προσωπικού", "Ανάλυση Τζίρου"],
      btn: "Επιλέξτε το",
      plusIcon: true
    },
    {
      warning: "Add-On",
      title: "Πακετο Τιμολογησης",
      price: 'Custom',
      list: ["Πολύ Οικονομικό", "Σύνδεση με ΥΠΑΗΕΣ", "Πολλαπλά POS", "Διασύνδεση με ΑΑΔΕ", "Χωρίς έκδοση Ζ"],
      btn: "Επιλέξτε το",
      hideCurrency: true
    }
  ];

  /**
   * Pro Pricing Data
   */
  halfYearData = [
    {
      title: "Βασικη Συνδρομη",
      price: 199,
      list: ["Εγκατάσταση", "Δημιουργία Καταλόγου", "Τεχνική Υποστήριξη", "Βασικά Στατιστικά", "Απεριόριστοι Χρήστες"],
      btn: "Επιλέξτε το"
    },
    {
      warning: "Add-On",
      title: "Πακετο Πελατων",
      price: 50,
      list: ["Δημιουργία QR Codes", "Κατάλογος Πελατών", "Παραγγελίες Πελατών", "Κάλεσμα Σερβιτόρου", "Ιστορικό Παραγγελιών"],
      btn: "Επιλέξτε το",
      plusIcon: true
    },
    {
      warning: "Add-On",
      title: "Πακετο Στατιστικων",
      price: 75,
      list: ["Πλήρη Στατιστικά", "Ανάλυση Πωλήσεων", "Στατιστικά Προϊόντων", "Στατιστικά Προσωπικού", "Ανάλυση Τζίρου"],
      btn: "Επιλέξτε το",
      plusIcon: true
    },
    {
      warning: "Add-On",
      title: "Πακετο Τιμολογησης",
      price: 'Custom',
      list: ["Πολύ Οικονομικό", "Σύνδεση με ΥΠΑΗΕΣ", "Πολλαπλά POS", "Διασύνδεση με ΑΑΔΕ", "Χωρίς έκδοση Ζ"],
      btn: "Επιλέξτε το",
      hideCurrency: true
    }
  ];

  /**
   * Monthly Pricing Data
   */
  yearlyData = [
    {
      title: "Βασικη Συνδρομη",
      price: 389,
      list: ["Εγκατάσταση", "Δημιουργία Καταλόγου", "Τεχνική Υποστήριξη", "Βασικά Στατιστικά", "Απεριόριστοι Χρήστες"],
      btn: "Επιλέξτε το"
    },
    {
      warning: "Add-On",
      title: "Πακετο Πελατων",
      price: 96,
      list: ["Δημιουργία QR Codes", "Κατάλογος Πελατών", "Παραγγελίες Πελατών", "Κάλεσμα Σερβιτόρου", "Ιστορικό Παραγγελιών"],
      btn: "Επιλέξτε το",
      plusIcon: true
    },
    {
      warning: "Add-On",
      title: "Πακετο Στατιστικων",
      price: 150,
      list: ["Πλήρη Στατιστικά", "Ανάλυση Πωλήσεων", "Στατιστικά Προϊόντων", "Στατιστικά Προσωπικού", "Ανάλυση Τζίρου"],
      btn: "Επιλέξτε το",
      plusIcon: true
    },
    {
      warning: "Add-On",
      title: "Πακετο Τιμολογησης",
      price: 'Custom',
      list: ["Πολύ Οικονομικό", "Σύνδεση με ΥΠΑΗΕΣ", "Πολλαπλά POS", "Διασύνδεση με ΑΑΔΕ", "Χωρίς έκδοση Ζ"],
      btn: "Επιλέξτε το",
      hideCurrency: true
    }
  ];

  ngOnInit(): void {
  }

  /**
   * Open modal for show the video
   * @param content content of modal
   */
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }

  mapView(mapcontent) {
    this.modalService.open(mapcontent, { windowClass: 'dark-modal', size: 'lg', centered: true })
  }

  sendEmail(): void {
    if(this.contactForm.valid) {
      this.isSending = true;
      const name = this.contactForm.get('name').value;
      const email = this.contactForm.get('email').value;
      const phone = this.contactForm.get('phone').value;
      const text = this.contactForm.get('text').value;
      this.contactService.sendEmail(name, email, phone, text).subscribe(
        () => {
          this.isSending = false;
          this.contactForm.reset();
          this.toastr.success('Λάβαμε το μήνυμα και κάποιος εκπρόσωπος μας θα επικοινωνήσει σύντομα μαζί σας.', 'Επιτυχής αποστολή!');
        },
        () => {
          this.isSending = false;
          this.toastr.error('Κάποιο σφάλμα προέκυψε κατά την αποστολή του μηνύματος σας.', 'Σφάλμα!');
        }
      );
    } else {
      this.contactForm.markAllAsTouched();
      this.toastr.warning('Παρακαλούμε συμπληρώστε ορθά όλα τα υποχρεωτικά πεδία στην φόρμα επικοινωνίας.', 'Προσοχή!');
    }
  }
}
